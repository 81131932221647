/* Global Styling */
html {
  padding: 0;
  margin: 0 auto;
  width: 100vw;
  box-sizing: border-box;
  text-size-adjust: none;
  font-size: 25px; /* ADJUST for Use of global REM units */
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color:#DEECF3;
}

.form-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  width: 53%; /* Will adjust this CSS property for Responsive Design */
}

/* IDG Logo img */
#db-logo-container > img {
  align-self: flex-start;
  padding-top: .6rem;
  width: 23.5vw;
  background-color: #FFFFFF;
}

#db-logo-container {
  position: sticky;
  text-align: center;
  background-color: #FFF;
  overflow: hidden;
  box-shadow: 0 1px 13px 0 rgb(0 0 0 / 19%);
  width: 100%;
  top: 0;
  margin-bottom: 1.5%;
  z-index: 5;
}

/* Intro Text Header */
h2 {
  font-size: 2.38vw;
  font-family: 'Lato';
  font-weight: 700;
  color: #0C1D42;
  letter-spacing: 0.1rem;
  margin-top: 0;
}

/* ===[Page Number Indicator]===*/
.page-num-indicator {
  align-self: flex-start;
  font-size: .6rem;
  color: #5c677c;
  font-family: "Maven Pro";
  font-weight: 700;
}

#page-1-indicator {
  padding-bottom: 2%;
}

/* ===[Intro Text Elements]===*/
#intro-content {
  text-align: center;
  margin: 0 auto;
}

.introText {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: 0 auto;
  width: 75%; /* Will adjust this CSS property for Responsive Design */
}

#intro-schedule {
  white-space: nowrap;
}

#intro-content > p {
  font-family: 'Lato';
  font-weight: 400;
  text-align: center;
  font-size: 0.8rem; /* Will adjust this CSS property for Responsive Design */
  line-height: 1.18rem;
  letter-spacing: .028rem;
  margin: 0 auto;
  color: #0C1D42;
}

#intro-content a {
  text-decoration: none;
  color: royalblue;
  font-weight: bold;
  cursor: pointer;
}

#intro-bold {
  white-space: pre-wrap;
}

p > span {
  font-weight: bolder;
}

.italic-font {
  font-style: italic;
  font-weight: bold;
  font-size: 0.7rem;
}

/* Forms, Form labels and field elements */
.formA,
.formB,
.formC {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
}

/* Page indicator on Page 2 */
#page-2-indicator {
  padding-bottom: 4%;
}

.form-container {
  padding-bottom: 8%;
  margin: 0 auto;
  width: 76%; /* Will adjust this CSS property for Responsive Design */
}

.form-field {
  margin-bottom: 1.35rem;
}

#dlConfirm {
  margin-bottom: 0;
}

#dlConfirm .errorMessage {
  margin-bottom: 0.1rem;
}

.inputLabels {
  display: flex;
  justify-content: space-between;
}

#checkbox-errorMessage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

label {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  /* cursor: pointer; */
  justify-content: center;
}

/* [Label Text] */
.inputLabels > p {
  font-family: 'Lato';
  font-size: .75rem;
  color: #0C1D42;
  align-self: flex-start;
  margin: 0;
  padding: 0 0 0.3rem 0.3rem;
  letter-spacing: 0.025rem;
  text-align: left;
}

.inputLabels > p::after {
  /* Asterisk after form field labels */
  content: '  \002A';
}

label > input,
label > select,
#date-picker-input,
.date-picker-input {
  /* Form Field Input Tags */
  font-size: 0.7rem;
  height: 2rem;
  border-radius: 20px;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: white;
  padding-left: 16px;
}

#date-picker-input {
  width: 100%;
}

.date-picker-input {
  width: calc(100% / 3);
}

label > input:focus {
  border-color: #bbb;
}

#dateOfBirth {
  width: 99%;
  font-family: "Lato";
  text-transform: uppercase;
}

#dateOfBirth::-webkit-calendar-picker-indicator {
  margin-right: 3%;
  padding-left: 70%;
  cursor: pointer;
}

button {
  display: inline-block;
  height: auto;
  width: 60%;
  padding: 1.55vh 0;
  color: white;
  background: #21bf53;
  font-size: 0.9rem;
  border-radius: 1.5rem;
  margin: 0 auto;
  border: none;
}

button:hover {
  background: #1a9942;
  font-weight: bold;
  border: 2px solid white;
  cursor: pointer;
}

/* =======[TLC Driver's License form field]======= */
div#tlcNumber {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

p#tlcNumber {
  align-self: flex-start;
  padding: 0, 0, 0, 0.3rem;
}

span#tlcNumber {
  padding: 0, 0, 0, 0.3rem;
  margin-bottom: -0.2rem;
}

span#tlcNumber.errorMessage {
  margin-top: -.4rem;
}
/* ===[whichCarCompaniesContainer Checkbox Group]=== */
#carCompanies {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* margin-top: 0.5rem; */
  width: 100%;
}

#carCompaniesLabel {
  /* formerValue: #whichCarCompaniesLabel*/
  display: block;
  width: 100%;
}

#carCompaniesLabel p {
  font-family: 'Lato';
  /* formerValue: #whichCarCompaniesLabel p */
  white-space: nowrap;
  font-size: 0.8rem;
  margin-top: 0.65rem;
  margin-bottom: 0;
  padding-bottom: 0.25rem;
  color:#0C1D42;
}

#carCompanies > .checkbox-option {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  height: auto;
  padding: 0.7rem 0.5rem;
  border-radius: 1.7rem;
  box-shadow: 0 1px 0.65rem 0 rgb(0 0 0 / 19%);
  width: 35%;
  height: 100%;
  margin-bottom: 1rem;
}

.uncheckedBox {
  color: #0C1D42;
  border: 2.5px solid rgba(96, 116, 133, 1);
}

.checkedBox {
  color: rgba(29, 219, 51);
  border: 2.5px solid rgba(29, 219, 51, 1);
}

#carCompanies input[type='checkbox'],
.radio-button > input[type='radio'] {
  margin: 0;
  width: auto;
  visibility: hidden;
}

.radio-button > input[type='radio'] {
  width: 100%;
  margin-top: -10px;
}

#carCompanies .checkbox-option .option {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: -0.5rem;
  width: 100%;
  cursor: pointer;
}

/* ======= [TLC Modal Styles] ======= */
.modal-background {
  position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: rgba(58, 58, 58, .75);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
  width: 429px;
  height: 282px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: relative;
}

/* ===[Healthcare Questions / Radio Buttons]=== */
.radio-buttons-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.radio-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8vw;
  border-radius: 30px;
  background-color: white;
  padding-top: 0.3rem;
  height: 2.75rem;
  box-shadow: 0 1px 0.65rem 0 rgb(0 0 0 / 19%);
}

.radio-button label span {
  font-size: 1.15rem;
}

.radio-buttons-container label span,
#coverageThroughTheState-label,
#payMoreThan20-label {
  cursor: pointer;
}

/* ===[Healthcare Questions Error Messages]=== */
span.healthCareErrors {
  margin: 0 auto;
}

/* ======= [Paragraphs Above & BelowSubmit Button] ======= */
.aboveSubmit {
  font-size: 0.6rem;
  color: #5c677c;
  line-height: 1.2;
  padding-bottom: 0.45rem;
  text-align: left;
}

.aboveSubmitLink,
#tlc-link {
  color: #1667ff;
  text-decoration: underline;
}

#tlc-link {
  white-space:nowrap;
  cursor: pointer;
}

.below-submit-titles {
  color: #0C1D42;
  font-size: .9rem;
  text-align: left;
  font-weight: bold;
  font-family: "Lato";
}

/* ========= [UI Error Messages] ========== */
.errorMessage {
  font-family: 'Barlow';
  color: #e73846;
  font-size: 0.66rem;
  margin: 0;
  letter-spacing: 0.02rem;
  white-space: nowrap;
  align-self: flex-end;
  padding-bottom: 0.3rem;
}

#whichCarCompaniesLabel {
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

/* #carCompaniesErrorText {
  transform: translateY(2);
} */

/* ============ [Thank You Page] ============= */

.thankYouPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: white;
}

.thankYou-dbLogo {
  height: 34vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* [DB Logo Container] */
.thankYou-dbLogo img {
  width: 50vw;
}

/* [Marketplace links Container] */
.thankYou-marketplace-links {
  color: white;
  background-color: #0C1D42;
  width: 100vw;
  height: 33vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thankYou-marketplace-links p {
  font-size: 1.3rem;
  font-family: "Lato";
  font-weight: 800;
  letter-spacing: .05rem;
  padding-top: 1.2%;
}

.appStores-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin: 0 auto;
  padding-bottom: 4%;
}

.appleStore-link-container img{
  width: 67%;
}

.playStore-link-container img{
  width: 78%;
}

/* [Bottom Thank You Container] */
.thankYou-last {
  background-color: #D3E3EB;
  width: 100vw;
  text-align: center;
  height: 33vh;
}

.thankYou-last h2{
  margin-top: 2%;
  font-size: 2rem;
  font-family: "Montserrat";
  font-weight: 800;
  margin-bottom: 1%;
}

.thankYou-last p {
  font-size: 1.25rem;
  padding-left: 10%;
  padding-right: 10%;
  font-family: 'Montserrat';
}

/* ========= [ Mui DatePicker CSS ] ========== */

/* [Remove Date Picker option to manually insert date with keyboard] */
.css-ctu4nj .PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

.css-dtbg0r .PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

/* [Date Picker for DOB form field] */
.date-picker-container {
  display: flex;
  align-items: center;
  position: relative;
}

/* [Calendar icon] */
.calendar-icon-container{
  position: absolute;
  right: 0;
  margin-right: 4%;
}

/* [Modal Close Icon] */
.close-modal-icon {
  position: absolute;
  height: 25px;
  width: 25px;
  top:0;
  right: 0;
}

/* ========= [ Media Query 1250px Width] ========== */
@media screen and (max-width: 1250px) {
  html {
    font-size: 23px;
  }

  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 27vw;
  }

  /* Form Section Width */
  .form-section {
    width: 58%;
  }

  /* Radio Button Label Font */
  .radio-button label span {
    font-size: 1.05rem;
  }

  /* Radio Button size */
  .radio-button {
    width: 10vw;
  }

  /* Form Field Label Fonts */
  .inputLabels > p {
    font-size: .72rem;
  }

  /* carCompanies Checkbox Label Fonts */
  #carCompanies .checkbox-option .option {
    font-size: 1.25rem;
  }

  /* carCompanies Checkbox size */
  #carCompanies > .checkbox-option {
    width: 32%;
  }
}
/* ========= [ Media Query 1100px Width] ========== */
@media screen and (max-width: 1100px) {
  html {
    font-size: 22px;
  }

  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 32vw;
  }

   /* Form Field Label Fonts */
   .inputLabels > p {
    font-size: .69rem;
  }

  /* Form Section Width */
  .form-section {
    width: 64%;
  }

  /* Radio Button size */
  .radio-button {
    width: 12vw;
  }

  /* [DB Logo Container] */
  .thankYou-dbLogo img {
    width: 55vw;
  }

  #dateOfBirth::-webkit-calendar-picker-indicator {
    padding-left: 66%;
  }
}
/* ========= [ Media Query 903px Width] ========== */
@media screen and (max-width: 903px) {
  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 36vw;
  }

  /* Form Section Width */
  .form-section {
    width: 69%;
  }

  /* First Page Title Font */
  h2 {
    font-size: 2.6vw;
  }

  /* Form Container Width */
  .form-container {
    width: 75%;
  }

  /* Radio Button size */
  .radio-button {
    width: 14vw;
  }

  /* carCompanies Checkbox Label Fonts */
  #carCompanies .checkbox-option .option {
    font-size: 1.125rem;
  }

  /* [DB Logo Container] */
  .thankYou-dbLogo img {
    width: 68vw;
  }

}
/* ========= [ Media Query 800px Width] ========== */
@media screen and (max-width: 800px) {
  /* Form Section Width */
  .form-section {
    width: 76%;
  }

  /* First Page Title Font */
  h2 {
    font-size: 3vw;
  }

  /* Radio Button size */
  .radio-button {
    width: 16vw;
  }

  /* [DB Logo Container] */
  .thankYou-dbLogo img {
    width: 73vw;
  }
  .thankYou-marketplace-links p {
    font-size: 1.2rem;
  }
}

/* ========= [ Media Query 700px Width] ========== */
@media screen and (max-width: 700px) {
  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 42vw;
  }

  /* Form Section Width */
  .form-section {
    width: 79%;
  }

  /* First Page Title Font */
  h2 {
    font-size: 3.4vw;
  }

  /* Radio Button size */
  .radio-button {
    width: 16vw;
  }

  /* [DB Logo Container] */
  .thankYou-dbLogo img {
    width: 88vw;
  }

  .thankYou-marketplace-links p {
    font-size: 1.2rem;
  }

  #dateOfBirth::-webkit-calendar-picker-indicator {
    padding-left: 60%;
  }
}

/* ========= [ Media Query 600px Width] ========== */
@media screen and (max-width: 600px) {
  html {
    font-size: 20px;
  }

  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 51vw;
  }

  /* First Page Title Font */
  h2 {
    font-size: 3.6vw;
  }

  /* Form Section Width */
  .form-section {
    width: 83%;
  }

  /* Radio Button Label Font */
  .radio-button label span {
    font-size: 1rem;
  }

  /* Radio Button size */
  .radio-button {
    width: 17vw;
  }

  /* [DB Logo Container] */
  .thankYou-dbLogo img {
    width: 98vw;
  }

  .thankYou-marketplace-links p {
    font-size: 1.15rem;
  }

}

/* ========= [ Media Query 500px Width] ========== */
@media screen and (max-width: 500px) {
  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 60vw;
  }

  /* Form Section Width */
  .form-section {
    width: 94%;
  }

   /* First Page Title Font */
   h2 {
    font-size: 4.1vw;
  }

  /* Radio Button size */
  .radio-button {
    width: 19vw;
  }

  .thankYou-marketplace-links p {
    font-size: .94rem;
  }

  .modal-container {
    width: 375px;
    height: 246.50px;
    padding: .9rem 1.5rem .9rem 1.5rem;
  }
}

/* ========= [ Media Query 450px Width] ========== */
@media screen and (max-width: 450px) {
  /* DB Header Logo Size */
  #db-logo-container > img {
    width: 67vw;
  }

  /* Form Section Width */
  .form-section {
    width: 100%;
  }

   /* First Page Title Font */
   h2 {
    font-size: 4.3vw;
  }

  /* Form Container Width */
  .form-container {
    width: 84%;
  }

  /* Radio Button size */
  .radio-button {
    width: 21vw;
  }

  #dateOfBirth::-webkit-calendar-picker-indicator {
    padding-left: 55%;
  }
  .modal-container {
    width: 375px;
    height: 230.07px;
    /* padding: .9rem 1.5rem .9rem 1.5rem; */
  }

  .modal-container img{
    height: 400px;
    width: 351px;
  }
}
.inputGooglePlaces {
    margin-bottom: -14px;
}

.inputGooglePlaces.active{
  /* border-bottom-right-radius: 0 !important; */
  /* border-bottom-left-radius: 0 !important; */
  /* border-top-right-radius: 20px!important; */
  /* border-top-left-radius: 20px!important; */
}

.basicOptionsPlaces {
  position: relative;
  border-radius: 0 0 3px 3px;
  background-color: white;
  overflow-y: auto;
  width: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 10;
}

.place-option {
    cursor: pointer;
    font-size: 0.8rem;
    text-align: left;
    padding: 8px 0px 8px 16px;
}

.place-option:hover {
  background-color: #e6e6e6;
}

/* ========= [ Media Query 400px Width] ========== */
@media screen and (max-width: 400px) {
  .modal-container {
    width: 340px;
    height: 223.50px;
    padding: .5rem;
  }
  
}
/* ========= [ Media Query 350px Width] ========== */
@media screen and (max-width: 350px) {
  .modal-container {
    width: 320px;
    height: 210.35px;
  }
  
}

/* ====================================================== */
